import React, { useState } from 'react';
import { Input } from './input';
import { Button } from './button';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import { Pencil1Icon } from '@radix-ui/react-icons';
interface QuickEditProps {
  className?: string;
  initialValue: string;
  onValueChange?: (newValue: string) => void;
}
export default function QuickEdit({
  className,
  initialValue,
  onValueChange
}: QuickEditProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [value, setValue] = useState(initialValue);
  const handleEditClick = () => setIsEditing(true);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (onValueChange) {
      onValueChange(event.target.value);
    }
  };
  const handleInputBlur = () => {
    setIsEditing(false);
  };
  return <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} data-sentry-component="QuickEdit" data-sentry-source-file="quick-edit.tsx">
      {isEditing ? <Input type="text" value={value} onChange={handleInputChange} onBlur={handleInputBlur} autoFocus /> : <p className={className}>
          {value}{' '}
          {isHovered && <Button variant="outline" onClick={handleEditClick} className='m-0 py-3 px-2 h-4'>
              <PencilSquareIcon className="h-4 w-4" />
            </Button>}
        </p>}
    </div>;
}